import Container from "react-bootstrap/Container";
import Lottie from "react-lottie";
import animationData from "./burger.json";
import { Helmet } from "react-helmet";
const Loading = () => {


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="bg-warning container-fluid" style={{ height: "100vh" }}>
      <Helmet>
        <title>{process.env.REACT_APP_COMPANY_NAME}</title>
      </Helmet>
      <Lottie options={defaultOptions} height={300} width={300} />
      <h1 className="text-center">Lütfen Bekleyiniz...</h1>
    </div>
  );
};

export default Loading;
