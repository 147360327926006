import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

const ProductControl = (props) => {
  const { productData } = props;

  return (
    <div className="row  w-90 p-3 m-2 shadow " style={{ minHeight: 200 }}>
      <div className="col-12 col-md-4">
        <Image
          src={productData.productImageURL}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
          fluid
        />
      </div>
      <div className="col-12 col-md-8 mt-2">
        <div className="d-flex w-90 p-1 justify-content-center align-items-center justify-content-md-start bg-warning">
          <p
            style={{
              fontFamily: "Arimo",
              fontWeight: "700",
              fontSize: "22px",
              color: "black",
              margin: "5px",
            }}
          >
            {productData.productName}
          </p>
        </div>
        <div className="d-flex w-90 h-100 pb-3  justify-content-center align-items-center justify-content-md-start ">
          <p
            className="text-center"
            style={{
              fontFamily: "Forum",
              fontWeight: "700",
              fontSize: "18px",
            }}
          >
            {productData.productDescription}
          </p>
        </div>
      </div>
      <div className="col-12 pt-2">
        <div className="row p-0 bg-light">
          {productData.productPrices.map((item, index) =>
            item.productSizeName !== "Standart" ? (
              <>
                <div
                  className="col d-flex flex-column p-1"
                  key={item.productSizeID}
                >
                  <div className="bg-black h-100 w-90 d-flex justify-content-center align-items-center rounded-top-1">
                    <p
                      style={{
                        fontFamily: "Arimo",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "white",
                        margin: "10px",
                      }}
                    >
                      {item.productSizeName}
                    </p>
                  </div>
                  <div className="d-flex h-100 w-90 justify-content-center align-items-center rounded-bottom-2 bg-warning">
                    <p
                      style={{
                        fontFamily: "Arimo",
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "black",
                        margin: "5px",
                      }}
                    >
                      {item.productPrice} TL
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductControl;
