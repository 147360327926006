import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GET_MENU_BY_ID, GET_MENU_PRODUCTS_BY_MENU_ID } from "./graphqlQueries";
import { useQuery } from "@apollo/client";
import MenuSections from "./components/MenuSections";
import { useState, useEffect } from "react";

import MenuButtons from "./components/MenuButtons";

const MenuButtonsSections = (props) => {
  const { storeInfo, pressedMenuButton } = props;

  const menuID = storeInfo.getStoreByStoreID.storeMenuID;

  const [menuSections, setMenuSections] = useState([]);

  const [selectedMenuSectionID, setSelectedSectionMenuID] = useState("");

  const [selectedMenuSectionProducts, setSelectedMenuSectionProducts] =
    useState([]);

  const [sectionTitle, setSectionTitle] = useState("");

  const { loading, data } = useQuery(GET_MENU_BY_ID, {
    variables: {
      menuId: menuID,
    },
  });

  const [menuSectionData, setMenuSectionData] = useState([]);

  useEffect(() => {
    if (data) {
      const sortedMenuSections = [...data.getMenuSectionsByMenuID].sort(
        (a, b) => a.menuSectionOrderNo - b.menuSectionOrderNo
      );

      console.log(sortedMenuSections);
      setMenuSectionData(sortedMenuSections);
      /*     setSelectedSectionMenuID(sortedMenuSections[0].id);
      setSectionTitle(sortedMenuSections[0].menuSectionTitle); */

      pressedMenuButton(
        sortedMenuSections[0].id,
        sortedMenuSections[0].menuSectionTitle
      );
    }
  }, [data]);

  return (
    <div className="scrolling-wrapper-flexbox">
      {data &&
        menuSectionData &&
        menuSectionData.map((section) => (
          <MenuButtons
            key={section.id}
            SectionData={section}
            pressedMenuButton={pressedMenuButton}
          />
        ))}
    </div>
  );
};

export default MenuButtonsSections;
