import { gql } from "@apollo/client";

const GET_STOREINFO = gql`
  query GetStoreByStoreID($storeId: ID!) {
    getStoreByStoreID(storeID: $storeId) {
      id
      companyID
      storeName
      storeSlogan
      storeAddress
      storeLatitude
      storeLongitude
      storeCity
      storeState
      storeCurrency
      storeZip
      storePhone
      storeEmail
      storeWebsite
      storeLogoURL
      storeBackgroudURL
      storeHours {
        openTime
        closeTime
        dayOfWeek
        fullClose
      }
      storeMenuID
      storeMenu {
        id
        menuName
      }
      storeActive
    }
  }
`;

const GET_MENU_BY_ID = gql`
  query Query($menuId: String!) {
    getMenuSectionsByMenuID(menuID: $menuId) {
      id
      menuSectionDescription
      menuSectionImage
      menuSectionMenuID
      menuSectionOrderNo
      menuSectionStatus
      menuSectionTitle
      menuSectionImageFileName
    }
  }
`;

const GET_MENU_PRODUCTS_BY_MENU_ID = gql`
  query GetMenuProductsByMenuID($menuId: ID!) {
    GetMenuProductsByMenuID(menuID: $menuId) {
      id
      productName
      productDescription
      productPrice
      productStatus
      productImageURL
      productOrderNo
      productMenuSectionID
      productMenuID
      productPrices {
        productSizeName
        productPrice
        productSizeID
      }
      productIngredientGroupID
    }
  }
`;

export { GET_STOREINFO, GET_MENU_BY_ID, GET_MENU_PRODUCTS_BY_MENU_ID };
