import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Header = (props) => {
  const { storeInfo } = props;
  return (
    <div className="overflow-x-hidden">
      <div className="d-flex flex-column pt-2 justify-content-center align-items-center  bg-warning">
        <img
          src={storeInfo.getStoreByStoreID.storeLogoURL}
          width="360"
          height="200"
        ></img>
        <h1 style={{ fontFamily: "Arimo", fontWeight: "800" }}>
          {storeInfo.getStoreByStoreID.storeName}
        </h1>
        {/*         <h3>{storeInfo.getStoreByStoreID.storeSlogan}</h3>
        <h6>{storeInfo.getStoreByStoreID.storeAddress}</h6>
        <h4>{storeInfo.getStoreByStoreID.storePhone}</h4> */}
      </div>
    </div>
  );
};

export default Header;
